<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        fab
        fixed
        bottom
        right
        style="border-radius: 50%;"
        v-bind="attrs"
        v-on="on"
        href="https://drive.google.com/file/d/1V_vAsM9ALzuA4mlLfpiFTMWcglXFHoaw/view"
        target="_blank"
      >
        <v-icon>{{ mdiFoodForkDrink }}</v-icon>
      </v-btn>
    </template>
    <span>See our food menu</span>
  </v-tooltip>
</template>

<script>
import { mdiFoodForkDrink } from "@mdi/js";
export default {
  data: () => ({ mdiFoodForkDrink: mdiFoodForkDrink })
};
</script>

<style lang="scss" scoped></style>
