<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4" md="5" class="d-flex justify-center">
        <v-img max-width="300" max-height="300" :src="store.offerImage">
          <template v-slot:placeholder>
            <v-sheet color="grey lighten-5">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" lg="8" md="7">
        <v-card class="mx-auto" max-width="600" elevation="0">
          <v-list subheader>
            <v-subheader
              class="primary-font grey--text text--darken-3 font-weight-medium"
            >
              Complementary offers & facilities
            </v-subheader>
            <br />

            <v-list-item v-for="offer in store.offers" :key="offer.title">
              <v-list-item-avatar>
                <v-img
                  :alt="`${offer} avatar`"
                  :src="store.checkCircle"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle
                  class="text--primary primary-font"
                  v-html="offer"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    store() {
      return {
        offers: this.$store.getters.getConstant("offers"),
        offerImage: this.$store.getters.getConstant("offerImage"),
        checkCircle: this.$store.getters.getConstant("checkCircle")
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.text--primary {
  font-size: 16px;
  -webkit-line-clamp: unset !important;
  line-height: 2em;
  white-space: normal;
}
.font-weight-medium {
  font-size: 26px;
}
</style>
