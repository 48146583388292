import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Rooms from "../views/Rooms.vue";
import Restaurant from "../views/Restaurant.vue";
import Conference from "../views/Conference.vue";
import Booking from "../views/Booking.vue";
import Contact from "../views/Contact.vue";
import Career from "../views/Career.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: Rooms
  },
  {
    path: "/restaurant",
    name: "Restaurant",
    component: Restaurant
  },
  {
    path: "/conference",
    name: "Conference",
    component: Conference
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/career",
    name: "Career",
    component: Career
  },
  {
    path: "*",
    name: "NotFound",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
