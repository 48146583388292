<template>
  <div>
    <v-card-title
      class="nonary-font font-weight-bold"
      v-text="title"
    ></v-card-title>
    <v-card-subtitle
      class="quaternary-font font-weight-medium mt-1"
      v-text="subtitle"
    ></v-card-subtitle>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: true }
  }
};
</script>
