<template>
  <v-container>
    <v-row class="mt-lg-16">
      <v-col
        v-for="(card, index) in homeBrandingCards"
        :key="card.title"
        cols="12"
        lg="3"
        md="3"
      >
        <v-card elevation="0">
          <v-list-item two-line>
            <v-list-item-avatar tile size="40">
              <img :src="card.image" alt="" />
            </v-list-item-avatar>
            <v-list-item-title class="itemTitle">
              {{ card.title }}
            </v-list-item-title>
            <v-divider
              class="divider-vertical"
              v-if="index < 3"
              vertical
            ></v-divider>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["homeBrandingCards"])
  }
};
</script>

<style lang="scss" scoped>
.itemTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

// vertical divider
@media screen and (max-width: 1024px) {
  .divider-vertical {
    display: none;
  }
}
</style>
