<template>
  <v-container>
    <v-row dense>
      <v-col class="py-8" v-for="(room, i) in rooms" :key="i" cols="12">
        <v-card class="rounded-card" elevation="2">
          <v-row>
            <v-col cols="12" lg="4">
              <image-carousel
                :photos="room.photos"
                height="200"
                padding="pa-3"
              />
            </v-col>
            <v-col cols="12" lg="8">
              <texts :title="room.title" :subtitle="room.subtitle" />
              <v-btn
                depressed
                color="tertiary"
                class="primary-font font-weight-medium primary--text hero-button mx-4"
                @click="openDialog(i)"
              >
                Close View
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <v-card>
          <v-container>
            <image-carousel
              :photos="rooms[index].photos"
              height="400"
              padding="pa-3"
              showArrows
            />
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ImageCarousel from "../ImageCarousel.vue";
import Texts from "./Texts.vue";
import { mdiCloseCircle } from "@mdi/js";

export default {
  data() {
    return {
      dialog: false,
      index: 0,
      mdiCloseCircle: mdiCloseCircle
    };
  },
  components: { ImageCarousel, Texts },
  computed: {
    ...mapState(["rooms"])
  },
  methods: {
    openDialog(i) {
      if (this.dialog == true) this.dialog = false;

      this.dialog = true;
      this.index = i;
    }
  }
};
</script>

<style lang="scss" scoped>
.rounded-card {
  border-radius: 20px 0px;
}
</style>
