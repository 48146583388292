<template>
  <div>
    <custom-hero
      :taglines="texts.taglines"
      :image="heroImage"
      :subTitle="texts.subTitle"
    />
    <blobs showMiniEllipse showBigEllipseWithSpark />
    <image-carousel
      height="600"
      padding="pa-4"
      :photos="restaurantPhotos"
      computeHeight
    />
    <fab />
  </div>
</template>

<script>
import ImageCarousel from "../components/ImageCarousel.vue";
import { mapState } from "vuex";
import Blobs from "../components/Blobs.vue";
import CustomHero from "../components/CustomHero.vue";
import Fab from "../components/Fab.vue";

export default {
  components: { ImageCarousel, Blobs, CustomHero, Fab },
  computed: {
    heroImage() {
      return this.$store.getters.getHeroImage("restaurant");
    },
    texts() {
      return this.$store.getters.getAppHeroTexts("restaurant");
    },
    ...mapState(["restaurantPhotos"])
  }
};
</script>
