<template>
  <v-container>
    <v-card class="rounded-card py-8 pa-lg-16" elevation="4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <span class="ml-8 septenary-font font-weight-light">
          Booking Details
        </span>

        <v-card-text>
          <!-- Check in starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium">
                Check In Date*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="checkInDate"
                persistent
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="checkInDate"
                    required
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="dateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="startingFrom"
                  v-model="checkInDate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(checkInDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Check in ends -->

          <!-- Check out starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium"
                >Check Out Date*</v-subheader
              >
            </v-col>
            <v-col cols="8">
              <v-dialog
                ref="secondDialog"
                v-model="secondModal"
                :return-value.sync="checkOutDate"
                persistent
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="checkOutDate"
                    required
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="dateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="checkInDate"
                  v-model="checkOutDate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="secondModal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.secondDialog.save(checkOutDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Check out ends -->

          <!-- Radio starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="mt-2 senary-font font-weight-medium">
                Room Type*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-radio-group v-model="group">
                <v-radio
                  v-for="option in radioOptions"
                  :key="option"
                  :label="option"
                  :value="option"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- Radio ends -->

          <!-- #room starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium">
                Number of Room*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="numberOfRoom"
                required
                outlined
                type="number"
                :rules="commonNumericRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- #room ends -->

          <!-- #nights starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium">
                Number of nights*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="numberOfNights"
                required
                outlined
                type="number"
                :rules="commonNumericRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- #nights ends -->

          <!-- #person starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium"
                >Number of Person*</v-subheader
              >
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="numberOfAdult"
                placeholder="Adult"
                required
                outlined
                type="number"
                :rules="commonNumericRules"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="numberOfChild"
                placeholder="Child"
                outlined
                type="number"
                :rules="childNumericRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- #person ends -->

          <span class="ml-4 septenary-font font-weight-light black--text">
            Personal Details
          </span>

          <!-- full name starts -->
          <v-row class="py-8">
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium">
                Full Name*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="fullName"
                outlined
                :rules="commonTextRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- full name ends -->

          <!-- email starts -->
          <v-row>
            <v-col cols="4">
              <v-subheader class="senary-font font-weight-medium">
                Email*
              </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="email"
                outlined
                :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- email ends -->

          <!-- contact starts -->
          <v-row>
            <v-col cols="12" lg="4" md="4">
              <v-subheader class="senary-font font-weight-medium">
                Contact Number*
              </v-subheader>
            </v-col>
            <v-col cols="12" lg="8" md="8">
              <vue-tel-input-vuetify
                class="pl-xs-4"
                :rules="contactRules"
                outlined
                label=""
                placeholder=""
                selectLabel="Code"
                defaultCountry="bd"
                @input="onInput"
              ></vue-tel-input-vuetify>
            </v-col>
          </v-row>
          <!-- contact ends -->

          <!-- passport starts -->
          <v-row>
            <v-col cols="12" lg="4" md="4">
              <v-subheader class="senary-font font-weight-medium">
                Scanned Passport (optional)
              </v-subheader>
            </v-col>
            <v-col cols="12" lg="8" md="8">
              <v-file-input
                v-model="scannedPassport"
                outlined
                prepend-icon=""
                class="pl-4 pl-md-0 pl-lg-0"
                accept="image/* , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document , application/pdf"
                :rules="passportRules"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <!-- passport ends -->

          <v-row>
            <v-col class="text-right">
              <v-btn
                :disabled="!valid"
                color="primary"
                :loading="isButtonLoading"
                @click="submit"
              >
                Book Now
              </v-btn>
            </v-col>
          </v-row>

          <cancellation />
        </v-card-text>
      </v-form>
    </v-card>
    <app-snackbar />
  </v-container>
</template>

<script>
import AppSnackbar from "../AppSnackbar.vue";
import Cancellation from "./Cancellation.vue";

export default {
  components: { Cancellation, AppSnackbar },
  data: () => ({
    modal: false,
    secondModal: false,
    valid: true,
    startingFrom: new Date().toISOString().substr(0, 10),
    checkInDate: null,
    checkOutDate: null,
    radioOptions: [
      "Deluxe",
      "Super Deluxe",
      "Aristocrat Deluxe",
      "Aristocrat Deluxe (Twin)",
      "Aristocrat Suite"
    ],
    group: "Deluxe",
    numberOfRoom: null,
    numberOfNights: null,
    numberOfAdult: null,
    numberOfChild: null,
    fullName: null,
    email: null,
    contact: {
      number: "",
      valid: false,
      country: undefined
    },
    scannedPassport: null,
    isButtonLoading: false,
    dateRules: [d => !!d || "Date is required"],
    commonTextRules: [t => !!t || "This field is required"],
    commonNumericRules: [
      n => !!n || "This field is required",
      n => n > 0 || "Should be at least 1"
    ],
    emailRules: [
      e => !!e || "E-mail is required",
      e => /.+@.+\..+/.test(e) || "E-mail must be valid"
    ],
    childNumericRules: [n => n === null || n >= 0 || "Cannot be negative"],
    passportRules: [
      value =>
        !value || value.size < 5000000 || "File size should be less than 5 MB!"
    ]
  }),
  computed: {
    contactRules() {
      return [
        t => !!t || "This field is required",
        this.contact.valid || `Invalid contact`
      ];
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");

        if (token) {
          const data = {
            checkIn: this.checkInDate,
            checkOut: this.checkOutDate,
            roomType: this.group,
            numberOfRoom: this.numberOfRoom,
            numberOfNights: this.numberOfNights,
            numberOfAdult: this.numberOfAdult,
            numberOfChild: this.numberOfChild === null ? 0 : this.numberOfChild,
            fullName: this.fullName,
            email: this.email,
            contact: this.contact
          };

          this.isButtonLoading = true;

          const payload = {
            to: "a_inn@hotmail.com",
            subject: `Booking by ${data.fullName}`,
            content: `
              <h1>Booking</h1>
              <p><b>Name:</b> ${data.fullName}</p>
              <p><b>Email:</b> ${data.email}</p>
              <p><b>Contact:</b> ${data.contact.number}</p>
              <p><b>Country (Provided Contact):</b> ${data.contact.country}</p>
              <p><b>Check in:</b> ${data.checkIn}</p>
              <p><b>Check out:</b> ${data.checkOut}</p>
              <p><b>Room type:</b> ${data.roomType}</p>
              <p><b>Number of rooms:</b> ${data.numberOfRoom}</p>
              <p><b>Total nights:</b> ${data.numberOfNights}</p>
              <p><b>Adult:</b> ${data.numberOfAdult} <b>Child:</b> ${data.numberOfChild}</p>
            `
          };

          const confirmation = {
            to: data.email,
            subject: `Hotel Aristocrat Inn Limited`,
            content: `
              Dear ${data.fullName}, 
              <br><br>
              We are pleased to inform you that your booking is confirmed. 
              
              <br><br>
              <u><b>Reservation details:</b></u>
              <br>
              <ul>
                <li><b>Check in:</b> ${data.checkIn}</li>
                <li><b>Check out:</b> ${data.checkOut}</li>
                <li><b>Room type:</b> ${data.roomType}</li>
                <li><b>Number of rooms:</b> ${data.numberOfRoom}</li>
                <li><b>Adult:</b> ${data.numberOfAdult} <b>Child:</b> ${data.numberOfChild}</li>
              </ul>

              
              Sincerely awaiting your visit, 
              <br>
              Hotel Aristocrat Inn Limited,
              <br>
              House # 12, Road #68, 68A, Gulshan-2, Dhaka-1212, Bangladesh
              <br>
              <b>Telephone:</b> +8802222281014, +8802222292327
              <br>
              <b>Cell:</b> +8801817019200, +8801924304304, +8801732279155
              <br>
              <b>Email:</b> a_inn@hotmail.com
              <br>
              <b>Facebook:</b> https://facebook.com/AristocratInn
              <br><br>
              <a href="https://goo.gl/maps/7rHStVuCYTWnz7PB9" target="_blank">
                <img width="300" src="https://i.imgur.com/PBR5DjN.png" />
              </a>
            `
          };

          if (!this.scannedPassport) {
            this.$store
              .dispatch("sendEmail", JSON.stringify(payload))
              .then(response => {
                if (response.status === "ok") {
                  this.$store.dispatch("showSuccessSnackBar");

                  // Send confirmation to customer
                  this.$store.dispatch(
                    "sendEmail",
                    JSON.stringify(confirmation)
                  );
                } else {
                  this.$store.dispatch("showFailedSnackBar");
                }
                this.resetAll();
              });
          } else {
            this.$store
              .dispatch("uploadFile", this.scannedPassport)
              .then(response => {
                if (response.url) {
                  payload.filepath = response.url;
                  this.$store
                    .dispatch("sendEmail", JSON.stringify(payload))
                    .then(response => {
                      if (response.status === "ok") {
                        this.$store.dispatch("showSuccessSnackBar");

                        // Send confirmation to customer
                        this.$store.dispatch(
                          "sendEmail",
                          JSON.stringify(confirmation)
                        );
                      } else {
                        this.$store.dispatch("showFailedSnackBar");
                      }
                      this.resetAll();
                    });
                } else {
                  this.$store.dispatch("showFailedSnackBar");
                  this.resetAll();
                }
              });
          }
        } else {
          this.$store.dispatch("showFailedSnackBar", "Verification failed");
        }
      }
    },
    resetAll() {
      this.isButtonLoading = false;
      this.$refs.form.reset();

      setTimeout(() => this.$router.go(this.$router.currentRoute), 5000);
    },
    onInput(formattedNumber, { number, valid, country }) {
      this.contact.number = number.international;
      this.contact.valid = valid;
      this.contact.country = country && country.name;
    }
  }
};
</script>

<style scoped>
.rounded-card {
  border-radius: 40px 0px;
}

@media only screen and (max-width: 768px) {
  .pl-xs-4 {
    padding-left: 16px;
  }
}
</style>
