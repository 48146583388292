<template>
  <v-footer color="navyblue" dark class="mt-16">
    <v-container>
      <v-row class="px-lg-16">
        <v-col cols="12" lg="6">
          <v-list class="transparent" subheader>
            <v-list-item>
              <v-list-item-title class="primary-font font-weight-medium">{{
                footerTexts.title
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle class="primary-font font-weight-medium">
                {{ footerTexts.address }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle
                class="primary-font font-weight-medium wrap"
                v-html="footerTexts.communication"
              >
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" lg="6">
          <v-list class="transparent" subheader>
            <v-list-item>
              <v-list-item-title class="primary-font font-weight-medium"
                >Follow us on</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <a target="_blank" rel="noopener" :href="footerTexts.facebook">
                  <v-icon large color="fb">{{ facebookIcon }}</v-icon>
                </a>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <v-col class="primary-font font-weight-medium text-center" cols="12">
      {{ footerTexts.copyright }}
    </v-col>
    <v-col class="septenary-font font-weight-medium text-center" cols="12">
      This site is protected by reCAPTCHA and the Google

      <a href="https://policies.google.com/privacy">Privacy Policy</a>
      and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </v-col>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";
import { mdiFacebook } from "@mdi/js";

export default {
  data: () => ({
    facebookIcon: mdiFacebook
  }),
  computed: {
    ...mapState(["footerTexts"])
  }
};
</script>

<style lang="scss" scoped>
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

.wrap {
  -webkit-line-clamp: unset !important;
  line-height: 2em;
  white-space: normal;
}

a {
  text-decoration: none;
}
</style>
