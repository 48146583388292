<template>
  <div class="mt-8">
    <v-btn
      depressed
      color="primary"
      class="primary-font font-weight-medium"
      :style="hero"
      to="/booking"
      large
    >
      Book Room
    </v-btn>
    <v-btn
      depressed
      color="primary"
      outlined
      class="primary-font font-weight-medium ml-2"
      :style="hero"
      to="/contact"
      large
    >
      Contact us
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    hero() {
      return "font-size: 12px";
    }
  }
};
</script>
