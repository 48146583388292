<template>
  <v-img :src="source" class="curve">
    <template v-slot:placeholder>
      <v-sheet height="400" color="grey lighten-5">
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </v-sheet>
    </template>
  </v-img>
</template>

<script>
export default {
  name: "CurvedTrapezium",
  props: {
    source: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.curve {
  height: 200px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 20px rgba(0, 133, 255, 0.2));
}

@media only screen and (min-width: 1024px) {
  .curve {
    width: 318px;
    height: 318px;
    border-radius: 28% 72% 67% 33% / 34% 22% 78% 66%;
  }
}

// border-radius: 35% 65% 65% 35% / 39% 15% 85% 61%;
</style>
