<template>
  <v-app>
    <nav-bar />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import NavBar from "./components/NavBar.vue";
export default {
  components: { NavBar, AppFooter },
  name: "App",
  mounted() {
    this.$store.dispatch("pingServer");
  }
};
</script>

<style lang="scss">
$main-font: "Raleway", sans-serif;

.primary-font {
  font-family: $main-font;
}

.secondary-font {
  font-family: "Montserrat", sans-serif;
}

.tertiary-font {
  font-family: "Didact Gothic", sans-serif;
}

.quaternary-font {
  font-family: $main-font;
  font-size: 14px;
}

.quinary-font {
  font-family: $main-font;
  font-size: 16px;
}

.senary-font {
  font-family: $main-font;
  font-size: 15px;
}

.septenary-font {
  font-family: $main-font;
  font-size: 12px;
}

.ocatnary-font {
  font-family: Raleway;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.nonary-font {
  font-family: Raleway;
  font-size: 22px;
  line-height: 16px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-leave-active {
  transition: opacity 0.3s ease;
  opacity: 0;
}

.v-btn {
  border-radius: 8px;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
