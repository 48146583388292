<template>
  <v-snackbar v-model="show" :color="color" multi-line>
    <div class="text-center">{{ text }}</div>
  </v-snackbar>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SHOW_MESSAGE_IN_SNACKBAR") {
        this.text = state.snackbarText;
        this.color = state.snackbarColor;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      color: "",
      text: ""
    };
  }
};
</script>
