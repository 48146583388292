<template>
  <div>
    <app-hero
      :taglines="texts.taglines"
      :image="heroImage"
      :subTitle="texts.subTitle"
    />
    <blobs showMiniEllipse showBigEllipse showBigEllipseWithSpark />
    <room-cards />
  </div>
</template>

<script>
import AppHero from "../components/AppHero.vue";
import Blobs from "../components/Blobs.vue";
import RoomCards from "../components/Rooms/RoomCards.vue";
export default {
  components: { AppHero, RoomCards, Blobs },
  computed: {
    heroImage() {
      return this.$store.getters.getHeroImage("rooms");
    },
    texts() {
      return this.$store.getters.getAppHeroTexts("rooms");
    }
  }
};
</script>

<style lang="scss" scoped></style>
