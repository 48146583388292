<template>
  <v-container>
    <v-card class="rounded-card" elevation="4">
      <v-form
        ref="contactForm"
        class="pa-6 pa-lg-16"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="4">
              <h3 class="nonary-font font-weight-medium">
                Hotel Aristorat Inn Ltd.
              </h3>
              <p class="mt-8 quinary-font">
                House # 12, Road # 68, 68/A,
              </p>
              <p class="quinary-font">Gulshan-2, Dhaka-1212</p>
              <p class="quinary-font">Bangladesh.</p>
              <p class="quinary-font">
                Tel:
                <a class="nodecoration black--text" href="tel:+8802222281014"
                  >+8802 222281014</a
                >,
                <a class="nodecoration black--text" href="tel:+8802 222292327"
                  >+8802 222292327</a
                >
              </p>
            </v-col>
            <v-col cols="12" lg="8">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="fullName"
                    label="Name"
                    outlined
                    :rules="commonTextRules"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    outlined
                    :rules="emailRules"
                  />
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-text-field
                  v-model="cell"
                  label="Contact (Optional)"
                  outlined
                  type="number"
                />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    outlined
                    :rules="commonTextRules"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    name="input-7-1"
                    auto-grow
                    v-model="message"
                    label="Message"
                    outlined
                    :rules="commonTextRules"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row>
          <v-col class="text-right">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              :loading="isButtonLoading"
              @click="submit"
            >
              Send Message
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <app-snackbar />
  </v-container>
</template>

<script>
import AppSnackbar from "../AppSnackbar.vue";
export default {
  components: { AppSnackbar },
  data: () => ({
    valid: true,
    fullName: "",
    email: "",
    cell: "",
    subject: "",
    message: "",
    isButtonLoading: false,
    commonTextRules: [t => !!t || "This field is required"],
    emailRules: [
      e => !!e || "E-mail is required",
      e => /.+@.+\..+/.test(e) || "E-mail must be valid"
    ]
  }),
  methods: {
    async submit() {
      if (this.$refs.contactForm.validate()) {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");

        if (token) {
          this.isButtonLoading = true;

          const payload = {
            to: "a_inn@hotmail.com",
            subject: this.subject,
            content: `
              <h1>Contact/Query</h1>
              <p><b>From:</b> ${this.fullName}</p>
              <p><b>Email:</b> ${this.email}</p>
              <p><b>Contact:</b> ${this.cell ? this.cell : "Not given"}</p>
              <p><b>Message:</b> ${this.message}</p>
            `
          };

          this.$store
            .dispatch("sendEmail", JSON.stringify(payload))
            .then(response => {
              if (response.status === "ok")
                this.$store.dispatch("showSuccessSnackBar");
              else this.$store.dispatch("showFailedSnackBar");
              this.resetAll();
            });
        } else {
          this.$store.dispatch("showFailedSnackBar", "Verification failed");
        }
      }
    },
    resetAll() {
      this.isButtonLoading = false;
      this.$refs.contactForm.reset();
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .pl-xs-4 {
    padding-left: 16px;
  }
}

.rounded-card {
  border-radius: 40px 0px;
}

.nodecoration {
  text-decoration: none;
}
</style>
