<template>
  <div>
    <app-hero
      :taglines="texts.taglines"
      :image="heroImage"
      :subTitle="texts.subTitle"
    />
    <blobs showMiniEllipse showBigEllipse showBigEllipseWithSpark />
    <form-card />
  </div>
</template>

<script>
import AppHero from "../components/AppHero.vue";
import Blobs from "../components/Blobs.vue";
import FormCard from "../components/Booking/FormCard.vue";
export default {
  components: { AppHero, FormCard, Blobs },
  computed: {
    heroImage() {
      return this.$store.getters.getHeroImage("booking");
    },
    texts() {
      return this.$store.getters.getAppHeroTexts("booking");
    }
  }
};
</script>

<style lang="scss" scoped></style>
