<template>
  <v-img :src="source" class="mx-lg-16" contain>
    <template v-slot:placeholder>
      <v-sheet height="400" color="grey lighten-5">
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </v-sheet>
    </template>
  </v-img>
</template>

<script>
export default {
  name: "PlainHeroImage",
  props: {
    source: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
