<template>
  <v-carousel
    dark
    :height="computeHeight ? computedHeight : height"
    hide-delimiters
    :show-arrows-on-hover="arrows"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">{{ chevronLeftIcon }}</v-icon>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">{{ chevronRightIcon }}</v-icon>
    </template>
    <v-carousel-item
      v-for="(photo, i) in photos"
      :key="i"
      :class="padding"
      eager
    >
      <v-img
        :src="photo"
        height="100%"
        lazy-src="https://firebasestorage.googleapis.com/v0/b/lite-weight-materials.appspot.com/o/aristocrat%2Fblurred.jpg?alt=media&token=003ad623-939e-466c-bdfb-cd84c18b37a6"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

export default {
  data: () => ({
    chevronLeftIcon: mdiChevronLeft,
    chevronRightIcon: mdiChevronRight
  }),
  props: {
    photos: {
      type: Array,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    padding: {
      type: String,
      required: true
    },
    computeHeight: {
      type: Boolean,
      default: false
    },
    showArrows: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
    arrows() {
      if (this.showArrows || this.isMobile) return false;
      else return true;
    },
    computedHeight() {
      if (this.isMobile) {
        return this.height / 2;
      } else {
        return this.height;
      }
    }
  }
};
</script>
