<template>
  <div>
    <hero-section />
    <branding />
    <services
      v-for="service in services"
      :key="service.title"
      :isExchnaged="isMobile ? true : service.isExchnaged"
      :showHeader="service.showHeader"
      :title="service.title"
      :subTitle="service.subTitle"
      :showMiniEllipse="service.showMiniEllipse"
      :showBigEllipseWithSpark="service.showBigEllipseWithSpark"
      :showBigEllipse="service.showBigEllipse"
      :image="service.image"
      :routeLink="service.routeLink"
    />
    <offer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeroSection from "../components/Home/HeroSection.vue";
import Branding from "../components/Home/Branding.vue";
import Services from "../components/Home/Services.vue";
import Offer from "../components/Home/Offer.vue";

export default {
  components: { HeroSection, Branding, Services, Offer },
  name: "Home",
  computed: {
    ...mapState(["services"]),
    isMobile() {
      const viewport = this.$vuetify.breakpoint.name;
      return viewport === "xs" || viewport === "sm";
    }
  }
};
</script>
