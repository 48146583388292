<template>
  <v-img
    :src="source"
    class="hero-image mt-lg-14"
    :class="renderShadow"
    max-width="500"
  >
    <template v-slot:placeholder>
      <v-sheet height="400" color="grey lighten-5">
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </v-sheet>
    </template>
  </v-img>
</template>

<script>
export default {
  name: "CurvedRectangleImage",
  props: {
    source: {
      type: String,
      required: true
    },
    hasShadow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    renderShadow() {
      return this.hasShadow ? "shadow" : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-image {
  height: 300px;
  border-radius: 85px 0px;
}

.shadow {
  filter: drop-shadow(0 0 0.4rem #969696);
}

@media screen and (max-width: 768px) {
  .hero-image {
    border-radius: 6px;
    width: 50%;
    height: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-image {
    border-radius: 65px 0px;
    width: 50%;
    height: 90%;
  }
}
</style>
