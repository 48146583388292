<template>
  <v-app-bar app class="white" hide-on-scroll elevation="2">
    <router-link to="/" class="primary-font brandTitle">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/lite-weight-materials.appspot.com/o/aristocrat%2Fcommon%2Flogo-horizontal.svg?alt=media&token=c9bab20f-d2b6-4321-a1ff-672bb140c0ff"
          transition="scale-transition"
          width="125"
        />
      </div>
    </router-link>

    <v-spacer></v-spacer>

    <v-btn
      v-for="menu in navbarMenu"
      :key="menu.routeName"
      :to="menu.route"
      text
      depressed
      active-class="primary--text"
      :ripple="false"
      class="d-none d-md-flex text-capitalize ml-3"
    >
      <span class="primary-font font-weight-medium navRouteTitle">{{
        menu.routeName
      }}</span>
    </v-btn>

    <!-- SHOW FOR SMALLER AND BELOW DEVICES -->
    <div class="d-flex d-md-none">
      <v-menu left bottom rounded="lg" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" aria-label="menu">
            <v-icon class="black--text">{{ menuIcon }}</v-icon>
          </v-btn>
        </template>

        <v-list v-for="menu in navbarMenu" :key="menu.routeName">
          <v-list-item :to="menu.route">
            <v-list-item-title
              class="primary-font font-weight-medium navRouteTitle"
              >{{ menu.routeName }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { mdiMenu } from "@mdi/js";

export default {
  data: () => ({
    menuIcon: mdiMenu
  }),
  computed: {
    ...mapState(["navbarMenu"])
  }
};
</script>

<style scoped>
.brandTitle {
  font-size: 20px;
  text-decoration: none;
}

.navRouteTitle {
  font-size: 14px;
}
</style>
