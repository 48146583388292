import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0085FF",
        secondary: "#cbe7fe",
        tertiary: "#e5f3fe",
        navyblue: "#202F40",
        fb: "#1878f3"
      },
      dark: {
        primary: "#0085FF",
        secondary: "#cbe7fe",
        tertiary: "#e5f3fe",
        navyblue: "#202F40",
        fb: "#1878f3"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg"
  }
});

export default vuetify;
