<template>
  <div class="hero-box">
    <!-- Absolute Vector Images -->
    <img
      v-for="vector in vectors"
      :key="vector.source"
      :id="vector.id"
      :src="vector.source"
      :alt="vector.id"
    />

    <!-- Hero Grid Starts -->
    <v-row>
      <v-col cols="12" lg="6" class="pa-8 pa-lg-16">
        <div
          v-for="title in homeHeroSectionTitles"
          :key="title.text"
          :class="title.margin"
        >
          <span :class="title.style" v-html="title.text"></span>
        </div>

        <button-group />
      </v-col>

      <v-col v-if="!isMobile" cols="12" lg="5" class="d-flex justify-end">
        <curved-rectangle-image :source="heroImage" hasShadow />
      </v-col>
    </v-row>
    <!-- Hero Grid Ends -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurvedRectangleImage from "../CurvedRectangleImage.vue";
import ButtonGroup from "./ButtonGroup.vue";

export default {
  components: { ButtonGroup, CurvedRectangleImage },
  computed: {
    ...mapState(["vectors", "homeHeroSectionTitles"]),
    heroImage() {
      return this.$store.getters.getHeroImage("home");
    },
    isMobile() {
      return ["sm", "xs", "md"].includes(this.$vuetify.breakpoint.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-box {
  overflow: hidden;
}
#ellipse {
  position: absolute;
  width: 1000px;
  height: 750px;
  top: -300px;
  right: -190px;
}
#block {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 100px;
  right: -50px;
}
#spark {
  position: absolute;
  left: 18.06px;
  top: 10.14px;
}
.header-title-small {
  font-size: 18px;
}
.header-title-big {
  display: block;
  font-size: 46px;
}
.header-title-medium {
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  #spark,
  #block,
  #ellipse {
    display: none;
  }
  .header-title-big {
    font-size: 38px;
  }
}
</style>
