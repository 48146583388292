<template>
  <div>
    <img
      v-if="showMiniEllipse"
      id="miniEllipse"
      :src="store.miniEllipse"
      alt="ellipse"
    />
    <img
      v-if="showBigEllipseWithSpark"
      id="bigEllipseWithSpark"
      :src="store.bigEllipse"
      alt="ellipse"
    />
    <img
      v-if="showBigEllipse"
      id="bigEllipse"
      :src="store.bigEllipse"
      alt="ellipse"
    />
  </div>
</template>

<script>
export default {
  computed: {
    store() {
      return {
        miniEllipse: this.$store.getters.getConstant("miniEllipse"),
        bigEllipse: this.$store.getters.getConstant("bigEllipse")
      };
    }
  },
  props: {
    showMiniEllipse: {
      type: Boolean,
      default: false
    },
    showBigEllipse: {
      type: Boolean,
      default: false
    },
    showBigEllipseWithSpark: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
#miniEllipse {
  position: absolute;
  width: 105.73px;
  height: 105.73px;
  left: -52.87px;
  top: 570.04px;
}

#bigEllipseWithSpark {
  position: absolute;
  width: 380.76px;
  height: 380.76px;
  right: -200px;
  top: 980px;
}

#bigEllipse {
  position: absolute;
  width: 380.76px;
  height: 380.76px;
  left: -242.17px;
  top: 1400px;
}

@media screen and (max-width: 1024px) {
  #miniEllipse,
  #bigEllipseWithSpark,
  #bigEllipse {
    display: none;
  }
}
</style>
