<template>
  <v-container>
    <div class="my-8">
      <!-- Section Title -->
      <h1
        v-if="showHeader"
        class="primary-font font-weight-bold text-center mb-10"
      >
        Services
      </h1>

      <blobs
        :showBigEllipse="showBigEllipse"
        :showBigEllipseWithSpark="showBigEllipseWithSpark"
        :showMiniEllipse="showMiniEllipse"
      />

      <!-- Dynamic Grid -->
      <v-row>
        <v-col cols="12" lg="4" md="4" v-if="isExchnaged">
          <curved-trapezium :source="image" />
        </v-col>
        <v-col cols="12" lg="8" md="8">
          <h2 class="primary-font font-weight-medium mt-6">
            {{ title }}
          </h2>
          <p class="primary-font mt-10">
            {{ subTitle }}
          </p>
          <v-btn
            depressed
            color="tertiary"
            class="primary-font font-weight-medium primary--text hero-button mt-4"
            v-if="routeLink"
            :to="routeLink"
            large
          >
            Explore it
          </v-btn>
        </v-col>
        <v-col cols="12" lg="4" md="4" v-if="!isExchnaged">
          <curved-trapezium :source="image" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Blobs from "../Blobs.vue";
import CurvedTrapezium from "./CurvedTrapezium.vue";

export default {
  components: { CurvedTrapezium, Blobs },
  computed: {
    store() {
      return {
        miniEllipse: this.$store.getters.getConstant("miniEllipse"),
        bigEllipse: this.$store.getters.getConstant("bigEllipse")
      };
    }
  },
  props: {
    isExchnaged: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    routeLink: {
      type: String
    },
    showMiniEllipse: {
      type: Boolean,
      default: false
    },
    showBigEllipse: {
      type: Boolean,
      default: false
    },
    showBigEllipseWithSpark: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  }
};
</script>
