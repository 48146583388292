<template>
  <v-container>
    <v-card class="rounded-card" elevation="4">
      <v-form
        ref="careerForm"
        class="pa-6 pa-lg-16"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="4">
              <h3 class="nonary-font font-weight-medium">
                Hotel Aristorat Inn Ltd.
              </h3>
              <p class="mt-8 quinary-font">
                House # 12, Road # 68, 68/A,
              </p>
              <p class="quinary-font">Gulshan-2, Dhaka-1212</p>
              <p class="quinary-font">Bangladesh.</p>
              <p class="quinary-font">
                Tel:
                <a class="nodecoration black--text" href="tel:+8802222281014"
                  >+8802 222281014</a
                >,
                <a class="nodecoration black--text" href="tel:+8802222292327"
                  >+8802 222292327</a
                >
              </p>
              <p class="quinary-font">
                Email:
                <a
                  class="nodecoration black--text"
                  href="mailto:job_ainn@outlook.com"
                  >job_ainn@outlook.com
                </a>
              </p>
            </v-col>

            <v-col cols="12" lg="8">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="fullName"
                    label="Name"
                    outlined
                    :rules="commonTextRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    outlined
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="contact"
                    label="Contact"
                    outlined
                    :rules="contactRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="postAppliedFor"
                    label="Post applied for"
                    outlined
                    :rules="commonTextRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-file-input
                  v-model="resume"
                  class="px-3"
                  outlined
                  label="Upload your resume"
                  accept="image/* , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document , application/pdf"
                  :rules="resumeRules"
                  prepend-icon=""
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row>
          <v-col class="text-right">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              :loading="isButtonLoading"
              @click="submit"
            >
              Apply
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <app-snackbar />
  </v-container>
</template>

<script>
import AppSnackbar from "../AppSnackbar.vue";
export default {
  components: { AppSnackbar },
  data: () => ({
    valid: true,
    fullName: "",
    email: "",
    contact: "",
    postAppliedFor: "",
    resume: null,
    resumeBlob: null,
    commonTextRules: [t => !!t || "This field is required"],
    isButtonLoading: false,
    contactRules: [
      c => !!c || "This field is required",
      c =>
        /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/.test(c) ||
        "Invalid Bangladeshi Number"
    ],
    emailRules: [
      e => !!e || "E-mail is required",
      e => /.+@.+\..+/.test(e) || "E-mail must be valid"
    ],
    resumeRules: [
      f => !!f || "Resume is required",
      value =>
        !value || value.size < 3000000 || "File size should be less than 3 MB!"
    ]
  }),
  methods: {
    async submit() {
      if (this.$refs.careerForm.validate()) {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");

        if (token) {
          this.isButtonLoading = true;

          const payload = {
            to: "job_ainn@outlook.com",
            subject: `Application for job from ${this.fullName}`,
            content: `
              <h1>Job Application</h1>
              <p><b>Applicant name:</b> ${this.fullName}</p>
              <p><b>Position Applied:</b> ${this.postAppliedFor}</p>
              <p><b>Email:</b> ${this.email}</p>
              <p><b>Contact:</b> ${this.contact}</p>
            `
          };

          this.$store.dispatch("uploadFile", this.resume).then(response => {
            if (response.url) {
              payload.filepath = response.url;
              this.$store
                .dispatch("sendEmail", JSON.stringify(payload))
                .then(response => {
                  if (response.status === "ok")
                    this.$store.dispatch("showSuccessSnackBar");
                  else this.$store.dispatch("showFailedSnackBar");
                  this.resetAll();
                });
            } else {
              this.$store.dispatch("showFailedSnackBar");
              this.resetAll();
            }
          });
        } else {
          this.$store.dispatch("showFailedSnackBar", "Verification failed");
        }
      }
    },
    resetAll() {
      this.isButtonLoading = false;
      this.$refs.careerForm.reset();
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .pl-xs-4 {
    padding-left: 16px;
  }
}
.rounded-card {
  border-radius: 40px 0px;
}

.nodecoration {
  text-decoration: none;
}
</style>
