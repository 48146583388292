<template>
  <div>
    <custom-hero
      :taglines="texts.taglines"
      :image="heroImage"
      :subTitle="texts.subTitle"
    />
    <blobs showMiniEllipse showBigEllipseWithSpark />
    <image-carousel
      height="600"
      padding="pa-4"
      :photos="conferencePhotos"
      computeHeight
    />
  </div>
</template>

<script>
import ImageCarousel from "../components/ImageCarousel.vue";
import { mapState } from "vuex";
import Blobs from "../components/Blobs.vue";
import CustomHero from "../components/CustomHero.vue";

export default {
  components: { ImageCarousel, Blobs, CustomHero },
  computed: {
    heroImage() {
      return this.$store.getters.getHeroImage("conference");
    },
    texts() {
      return this.$store.getters.getAppHeroTexts("conference");
    },
    ...mapState(["conferencePhotos"])
  }
};
</script>

<style lang="scss" scoped></style>
