<template>
  <div>
    <v-container class="tertiary mb-6" fluid>
      <v-row class="pb-lg-16 pt-lg-0 pa-md-10">
        <v-img id="blockImage" :src="blockImage" />

        <v-col cols="12" lg="6" md="5" :class="flex">
          <div class="pt-lg-16 mt-lg-16 mb-lg-16">
            <h6
              class="primary-font big font-weight-medium"
              v-for="line in taglines"
              :key="line"
            >
              {{ line }}
            </h6>
          </div>
        </v-col>
        <v-col cols="12" lg="6" md="7" class="d-flex" :class="imageFlex">
          <plain-hero-image class="mt-lg-12" :source="image" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PlainHeroImage from "./PlainHeroImage.vue";
export default {
  components: { PlainHeroImage },
  props: {
    taglines: {
      type: Array,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  computed: {
    isDesktop() {
      return ["lg", "xl"].includes(this.$vuetify.breakpoint.name);
    },
    isTablet() {
      return ["md"].includes(this.$vuetify.breakpoint.name);
    },
    imageFlex() {
      return this.isDesktop || this.isTablet
        ? "justify-space-around"
        : "justify-start";
    },
    flex() {
      return this.isDesktop ? "d-flex justify-center" : "d-flex";
    },
    cleanEllipse() {
      return this.$store.getters.getConstant("cleanEllipse");
    },
    blockImage() {
      return this.$store.getters.getConstant("blockImage");
    },
    alignment() {
      return this.isDesktop || this.isTablet ? "text-center" : "text-left";
    }
  }
};
</script>

<style lang="scss" scoped>
#ellipse {
  position: absolute;
  width: 250px;
  height: 250px;
  left: 48%;
  top: 110px;
}

#blockImage {
  position: absolute;
  width: 120px;
  height: 130px;
  left: 93%;
  top: -5%;
}

// Starting From 19" Display
@media screen and (min-width: 1366px) {
  #ellipse {
    left: 50%;
  }
}

.big {
  font-size: 24px;
}

@media screen and (max-width: 1024px) {
  #ellipse,
  #blockImage {
    display: none;
  }
  .big {
    font-size: 15px;
  }
}
</style>
